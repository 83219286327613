import { connect } from 'react-redux'
import { WorkflowPanelInboxEffects } from './effects'
import { downloadAttachment } from 'application/redux/actions/attachments'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { getWfpMessageByIdRequest, getWfpMessagesListRequest, resetWfpDeletedMessages, resetWfpMessageDetails } from 'application/redux/actions/pages/workflow_panel/inbox'

const mapStateToProps = state => ({
    inbox: state.pages.workflowPanel.inbox
})

const mapDispatchToProps = dispatch => {
    return {
        getMessages: (offset, limit, onlyUnreadMessages) => {
            dispatch(getWfpMessagesListRequest(offset, limit, onlyUnreadMessages))
        },
        getMessageDetails: (messageId) => {
            dispatch(getWfpMessageByIdRequest(messageId))
        },
        downloadAttachment: (
            attId, 
            attName, 
            damageId
        ) => {
            dispatch(
                downloadAttachment(
                    attId, 
                    attName, 
                    damageId
                )
            )
        },
        openMessageRelatedDialog: (
            name,
            initiallyLoaded, 
            payload, 
            title
        ) => {
            dispatch(
                openDialogByName(
                    name,
                    initiallyLoaded, 
                    payload, 
                    title
                )
            )
        },
        resetWfpMessages: () => {
            dispatch(resetWfpDeletedMessages())
            dispatch(resetWfpMessageDetails())
        }
    }
}

export const WorkflowPanelInbox = connect(
    mapStateToProps,
    mapDispatchToProps
)(WorkflowPanelInboxEffects)