import { ACTIONS } from 'application/constants'

export const getWfpMessagesListRequest = (offset, limit, onlyUnreadMessages) => {
    return {
        type: ACTIONS.INBOX_WFP_GET_MESSAGES_REQUEST,
        offset, limit, onlyUnreadMessages
    }
}

export const populateWfpMessagesList = (messages, messagesTotal) => {
    return {
        type: ACTIONS.INBOX_WFP_POPULATE_MESSAGES,
        messages, messagesTotal
    }
}

export const updateWfpDeletedMessages = (messageId) => {
    return {
        type: ACTIONS.INBOX_WFP_UPDATE_DELETED_MESSAGES,
        messageId
    }
}

export const resetWfpDeletedMessages = () => {
    return {
        type: ACTIONS.INBOX_WFP_RESET_DELETED_MESSAGES
    }
}

export const resetWfpMessageDetails = () => {
    return {
        type: ACTIONS.INBOX_WFP_RESET_MESSAGE_DETAILS
    }
}

export const getWfpMessageByIdRequest = (messageId) => {
    return {
        type: ACTIONS.INBOX_WFP_GET_MESSAGE_BY_ID,
        messageId
    }
}


export const populateWfpMessageDetails = (messageDetails) => ({
    type: ACTIONS.INBOX_WFP_POPULATE_MESSAGE_DETAILS,
    messageDetails,
})
