import styled from 'styled-components'

export const WidgetHeadline = styled.h2`
    margin: 8px 0 0;
    font-size: ${props => props.theme.fontSize.small};
    font-weight: ${props => props.theme.fontWeight.smallRegular};
    color: ${props => props.theme.color.anthracite};
    padding: 0;
`

export const WidgetHeadlineRight = styled.span`
    float: right;
`

export const WidgetSubHeadline = styled.h3`
    font-size: ${props => props.theme.fontSize.standard};
    font-weight: ${props => props.cssRegular !== undefined && props.cssRegular === true ? props.theme.fontWeight.standardRegular : props.theme.fontWeight.standardBold };
    padding: 0;
    margin: 8px 0;
    display: flex;
    align-content: center;
`

export const BulletList = styled.ul`
    padding: 0 0 0 16px;
    list-style: none;
    margin: 0;
`

export const BulletListItem = styled.li`
    font-size: 14px;
    padding-top: 8px;
    font-size: ${props => props.theme.fontSize.standard};
    position: relative;
    ${props => props.hideBullet ? `
    &:before {
        content: ""; 
    }
  ` : `
    &:before {
        content: "\\2022"; 
        color: ${props.cssColor !== undefined ? props.cssColor : props.theme.color.primary};
        font-weight: bold;
        display: inline-block; 
        width: 1em; 
        position: absolute;
        left: -16px
    }
  `}
    
`

export const SubListItem = styled.li`
    font-size: 14px;
    padding-left: 14px;
    display: block;
    padding-top: 2px;
    word-break: break-all;
`

export const BulletListLabel = styled.span`
    display: inline-block;
    min-width: ${props => props.cssWidth !== undefined ? props.cssWidth : '96px'};
    font-size: ${props => props.theme.fontSize.standard};
    font-weight: ${props => props.theme.fontWeight.headLine1};
    margin-right: 4px;
    word-break: break-all;
    & i {
        position: relative;
        top: 2px;
    }
`

export const WidgetErrorMessage = styled.div`
    background: ${props => props.theme.color.primaryBackground};
    border: 1px dashed ${props => props.theme.color.primary};
    padding:  ${props => props.cssPadding !== undefined ? props.cssPadding : '8px'};
    font-size: ${props => props.theme.fontSize.standard};
    color: ${props => props.theme.color.anthracite};
    border-radius: 2px;
`

export const WidgetInformation = styled.div`
    background: ${props => props.theme.color.gray10};
    border: 1px dashed ${props => props.theme.color.gray70};
    padding: 8px;
    font-size: ${props => props.theme.fontSize.standard};
    color: ${props => props.theme.color.anthracite};
    border-radius: 2px;
    margin-top: 8px;
`

export const WidgetTabsNavigation = styled.div`
    margin: 6px 0;
`

export const WidgetCardLayout = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    background: ${props => props.backgroundColor !== undefined ? props.backgroundColor : props.theme.color.transparent};
    padding: ${props => props.isBlank ? '16px' : '0px'};
`

export const WidgetCardLayoutLeft = styled.div`
    align-items: flex-start;
    display: flex;
    margin-right: 12px;
    width: 90px;
`

export const WidgetCardLayoutRight = styled.div`
    flex: 1 1 auto;
`

export const WidgetCardLayoutIcon = styled.span`
    position: relative;
    left: -12px;
    & > i {
        font-size: ${props => props.theme.iconSize.extraLarge};
        color: ${props => props.theme.color.gray15};
    }
`

export const WidgetText = styled.div`
    font-size: ${props => props.theme.fontSize.standard};
    color: ${props => props.theme.color.anthracite};
    padding: ${props => props.isBlank ? '8px' : '0px'};
    line-height: ${props => props.theme.fontSize.headline3};
`

export const WidgetTextCentered = styled(WidgetText)`
    display: flex;
    align-items: center;
`