import { connect } from 'react-redux'
import {InboxEffects} from './effects'
import { withLoggedInUser } from 'application/components/context/logged_in'
import { getMessageByIdRequest, getMessagesListRequest, resetDeletedMessages, resetMessageDetails } from 'application/redux/actions/pages/inbox'
import { downloadAttachment } from 'application/redux/actions/attachments'
import { openDialogByName } from 'application/redux/actions/dialogs'

const mapStateToProps = state => ({
    inbox: state.pages.inbox
})

const mapDispatchToProps = dispatch => {
    return {
        getMessages: (offset, limit, onlyUnreadMessages) => {
            dispatch(getMessagesListRequest(offset, limit, onlyUnreadMessages))
        },
        getMessageDetails: (damageId, messageId) => {
            dispatch(getMessageByIdRequest(damageId, messageId))
        },
        downloadAttachment: (
            attId, 
            attName, 
            damageId
        ) => {
            dispatch(
                downloadAttachment(
                    attId, 
                    attName, 
                    damageId
                )
            )
        },
        openMessageRelatedDialog: (
            name,
            initiallyLoaded, 
            payload, 
            title
        ) => {
            dispatch(
                openDialogByName(
                    name,
                    initiallyLoaded, 
                    payload, 
                    title
                )
            )
        },
        resetMessages: () => {
            dispatch(resetDeletedMessages())
            dispatch(resetMessageDetails())
        }
    }
}

export const Inbox = connect(
    mapStateToProps,
    mapDispatchToProps
)(withLoggedInUser(InboxEffects))