import { DIALOG } from 'application/constants'
import { DeleteAttachment } from 'application/components/dialogs/attachment'
import { DefaultDialogContent, DefaultHeader } from 'application/components/dialogs'
import { CommissionDekraExpert, CommissionOtherExpert } from 'application/components/dialogs/experts'
import { CommissionLawfirm, DeleteLawfirm } from 'application/components/dialogs/lawyers'
import { DamageInvoices } from 'application/components/dialogs/damage_invoices'
import { SwitchDamageCustomer } from 'application/components/dialogs/switch_damage_customer'
import { CreateVehicleAndKeeperQRCode } from 'application/components/dialogs/customer/create_qr_code'
import { TaskAssignUser } from 'application/components/dialogs/tasks/internal/assign_user'


import {
    CreateTask,
    DeleteTask,
    EditTask,
    ReopenTask
} from 'application/components/dialogs/tasks/internal'

import {
    CreateTaskworkflowPanel,
    DeleteTaskWorkflowPanel,
    EditTaskWorkflowPanel
} from 'application/components/dialogs/tasks/workflow_panel'

import {
    AssignPreferredBranch,
    EditUser,
    DeleteUser,
    EditUserGroups,
    NotificationSettings
} from 'application/components/dialogs/users'

import {
    AddCustomState,
    DeleteCustomState,
    EditCustomState
} from 'application/components/dialogs/custom_state'

import {
    CategorySelect,
    CategorySelectWithUploader,
    AddDocumentCategory,
    DeleteDocumentCategory,
    EditDocumentCategory
} from 'application/components/dialogs/categories'

import {
    DeleteMessageDialog,
    CreateMessageWorkflowPanel,
    MoveMessageDialog
} from 'application/components/dialogs/messages'

import {
    CreatePayment,
    CancelPayment
} from 'application/components/dialogs/payment'

import {
    DeleteInvoice,
    CreateInvoice
} from 'application/components/dialogs/invoice'

import {
    DeleteDamage,
    CreateDamage,
    CreateDamageKickstart
} from 'application/components/dialogs/damage'

import {
    EditCustomer,
    DeleteCustomer
} from 'application/components/dialogs/customer'

import {
    EditCaseNotes,
    CreateCaseNotes
} from 'application/components/dialogs/case_notes'


import {
    MoDamagesChangeState,
    MoDamageDelete,
    MoDocsChangeCategory,
    MoDocsIsExportedToLawfirm,
    MoDocsDelete,
    MoTasksDelete
} from 'application/components/dialogs/mass_operations'

import {
    AddRole,
    AddGroup,
    EditGroup,
    EditUsersInGroup,
    RemoveGroup,
    RemoveUserFromGroup,
    CreateBranch,
    ChangeBranch,
    EditBranch,
    RemoveBranch,
    CreateBranchQRCode
} from 'application/components/dialogs/branch_management'

import {
    ExportToLawFirmConfirmation
} from 'application/components/dialogs/export_to_lawfirm_confirmation'
import { CreateSmartConnectEmail } from 'application/components/dialogs/create_smart_connect_email'

import { CreateUser } from 'application/components/dialogs/users/create/create_user/container'

import {
    CreateCostCoverage
} from 'application/components/dialogs/create_cost_coverage'

import { 
    CreatePowerOfAttorney 
} from 'application/components/dialogs/create_power_of_attorney'


import {DeleteAccountlogo} from 'application/components/dialogs/account/delete'

import {
    AddComprehensiveDamageType,
    DeleteComprehensiveDamageType,
    EditComprehensiveDamageType
} from 'application/components/dialogs/comprehensive_damage_types'

export const dialogSizeMapper = name => {
    switch (name) {
    case DIALOG.NAME.DELETE_ATTACHMENT:
    case DIALOG.NAME.DELETE_LAWFIRM:
        return DIALOG.SIZE.SMALL
    case DIALOG.NAME.EXPORT_TO_LAWFIRM_CONFIRMATION:
    case DIALOG.NAME.BRANCH_MANAGEMENT_CHANGE_BRANCH:
    case DIALOG.NAME.DELETE_CUSTOMER:
        return DIALOG.SIZE.MEDIUM
    case DIALOG.NAME.MO_TASKS_DELETE:
    case DIALOG.NAME.COMMISSION_DEKRA_EXPERT:
    case DIALOG.NAME.COMMISSION_DEKRA_EXPERT_FROM_DAMAGE_MANAGER:
        return DIALOG.SIZE.LARGE
    default: return DIALOG.SIZE.MEDIUM50
    }
}

export const dialogComponentsMapper = dialog => {
    switch (dialog.name) {
    case DIALOG.NAME.CREATE_DAMAGE:
        return {
            MainComponent: CreateDamage
        }
    case DIALOG.NAME.CREATE_DAMAGE_KICKSTART:
        return {
            MainComponent: CreateDamageKickstart
        }
    case DIALOG.NAME.CREATE_TASK_FROM_DAMAGE_MANAGER:
    case DIALOG.NAME.CREATE_TASK_FROM_TASKBOARD:
        return {
            MainComponent: CreateTask
        }
    case DIALOG.NAME.DELETE_MESSAGE:
        return {
            MainComponent: DeleteMessageDialog
        }
    case DIALOG.NAME.MOVE_MESSAGE:
        return {
            MainComponent: MoveMessageDialog
        }
    case DIALOG.NAME.DELETE_DAMAGE:
        return {
            MainComponent: DeleteDamage
        }
    case DIALOG.NAME.EDIT_CURRENT_USER:
    case DIALOG.NAME.EDIT_USER_FROM_LIST:
        return {
            MainComponent: EditUser
        }
    case DIALOG.NAME.DELETE_USER:
        return {
            MainComponent: DeleteUser
        }
    case DIALOG.NAME.CREATE_USER:
        return {
            MainComponent: CreateUser
        }
    case DIALOG.NAME.CATEGORY_SELECT_FROM_TASK_WIDGET:
    case DIALOG.NAME.CATEGORY_UPLOAD_WORKFLOW_PANEL:
        return {
            MainComponent: CategorySelect
        }
    case DIALOG.NAME.CATEGORY_UPLOAD:
    case DIALOG.NAME.CATEGORY_UPLOAD_FROM_DOCUMENT_CENTER:
    case DIALOG.NAME.ADD_DOCUMENTS_TO_INVOICE:
        return {
            MainComponent: CategorySelectWithUploader
        }
    case DIALOG.NAME.COMMISSION_DEKRA_EXPERT:
    case DIALOG.NAME.COMMISSION_DEKRA_EXPERT_FROM_DAMAGE_MANAGER:
        return {
            MainComponent: CommissionDekraExpert.MainComponent,
            AdditionalHeader: CommissionDekraExpert.AdditionalHeader
        }
    case DIALOG.NAME.COMMISSION_OTHER_EXPERT:
        return {
            MainComponent: CommissionOtherExpert.MainComponent
        }
    case DIALOG.NAME.COMISSION_LAWFIRM:
        return {
            MainComponent: CommissionLawfirm
        }
    case DIALOG.NAME.DELETE_LAWFIRM:
        return {
            MainComponent: DeleteLawfirm
        }
    case DIALOG.NAME.DELETE_TASK_FROM_DAMAGE_MANAGER:
    case DIALOG.NAME.DELETE_TASK:
        return {
            MainComponent: DeleteTask
        }
    case DIALOG.NAME.CREATE_CASE_NOTES:
        return {
            MainComponent: CreateCaseNotes
        }
    case DIALOG.NAME.EDIT_CASE_NOTES:
        return {
            MainComponent: EditCaseNotes
        }
    case DIALOG.NAME.DELETE_ATTACHMENT:
        return {
            MainComponent: DeleteAttachment
        }
    case DIALOG.NAME.CREATE_TASK_FROM_WORKFLOW_PANEL:
        return {
            MainComponent: CreateTaskworkflowPanel
        }
    case DIALOG.NAME.CREATE_INVOICE_WORKFLOW_PANEL:
    case DIALOG.NAME.CREATE_INVOICE_LAWYER_COMMUNICATION:
        return {
            MainComponent: CreateInvoice
        }
    case DIALOG.NAME.CANCEL_PAYMENT_WORKFLOW_PANEL:
    case DIALOG.NAME.CANCEL_PAYMENT_LAWYER_COMMUNICATION:
        return {
            MainComponent: CancelPayment
        }
    case DIALOG.NAME.CREATE_PAYMENT_LAWYER_COMMUNICATION:
    case DIALOG.NAME.CREATE_PAYMENT_WORKFLOW_PANEL:
        return {
            MainComponent: CreatePayment
        }
    case DIALOG.NAME.DELETE_INVOICE_WORKFLOW_PANEL:
    case DIALOG.NAME.DELETE_INVOICE_LAWYER_COMMUNICATION:
        return {
            MainComponent: DeleteInvoice
        }
    case DIALOG.NAME.EDIT_TASK:
        return {
            MainComponent: EditTask
        }
    case DIALOG.NAME.DELETE_TASK_WORKFLOW_PANEL:
        return {
            MainComponent: DeleteTaskWorkflowPanel
        }
    case DIALOG.NAME.EDIT_TASK_WORKFLOW_PANEL:
        return {
            MainComponent: EditTaskWorkflowPanel
        }
    case DIALOG.NAME.CREATE_MESSAGE_WORKFLOW_PANEL:
    case DIALOG.NAME.CREATE_MESSAGE_FOR_LAWFIRM:
        return {
            MainComponent: CreateMessageWorkflowPanel
        }
    case DIALOG.NAME.EDIT_CUSTOMER:
    case DIALOG.NAME.CREATE_CUSTOMER:
    case DIALOG.NAME.EDIT_CUSTOMER_FROM_WIDGET:
    case DIALOG.NAME.EDIT_CUSTOMER_FROM_DIALOG:
        return {
            MainComponent: EditCustomer
        }
    case DIALOG.NAME.DELETE_CUSTOMER:
        return {
            MainComponent: DeleteCustomer
        }
    case DIALOG.NAME.DAMAGE_INVOICES:
        return {
            MainComponent: DamageInvoices.MainComponent,
            AdditionalHeader: DamageInvoices.AdditionalHeader
        }
    case DIALOG.NAME.MO_DOCUMENTS_DELETE:
        return {
            MainComponent: MoDocsDelete
        }
    case DIALOG.NAME.MO_DOCUMENTS_CHANGE_CATEGORY:
        return {
            MainComponent: MoDocsChangeCategory
        }
    case DIALOG.NAME.MO_DOCUMENTS_IS_EXPORTED_TO_LAWFIRM:
        return {
            MainComponent: MoDocsIsExportedToLawfirm
        }
    case DIALOG.NAME.BRANCH_MANAGEMENT_ADD_ROLE:
        return {
            MainComponent: AddRole
        }
    case DIALOG.NAME.BRANCH_MANAGEMENT_ADD_GROUP:
        return {
            MainComponent: AddGroup
        }
    case DIALOG.NAME.BRANCH_MANAGEMENT_EDIT_GROUP:
        return {
            MainComponent: EditGroup
        }
    case DIALOG.NAME.BRANCH_MANAGEMENT_ADD_BRANCH:
        return {
            MainComponent: CreateBranch
        }
    case DIALOG.NAME.BRANCH_MANAGEMENT_CHANGE_BRANCH:
        return {
            MainComponent: ChangeBranch
        }
    case DIALOG.NAME.ADD_DOCUMENT_CATEGORY:
        return {
            MainComponent: AddDocumentCategory
        }
    case DIALOG.NAME.DELETE_DOCUMENT_CATEGORY:
        return {
            MainComponent: DeleteDocumentCategory
        }
    case DIALOG.NAME.EDIT_DOCUMENT_CATEGORY:
        return {
            MainComponent: EditDocumentCategory
        }
    case DIALOG.NAME.ADD_CUSTOM_STATE:
        return {
            MainComponent: AddCustomState
        }
    case DIALOG.NAME.EDIT_CUSTOM_STATE:
        return {
            MainComponent: EditCustomState
        }
    case DIALOG.NAME.DELETE_CUSTOM_STATE:
        return {
            MainComponent: DeleteCustomState
        }
    case DIALOG.NAME.EDIT_BRANCH:
        return {
            MainComponent: EditBranch
        }
    case DIALOG.NAME.MO_DAMAGES_DELETE:
        return {
            MainComponent: MoDamageDelete
        }
    case DIALOG.NAME.MO_DAMAGES_CHANGE_STATE:
        return {
            MainComponent: MoDamagesChangeState
        }
    case DIALOG.NAME.SWITCH_DAMAGE_CUSTOMER:
        return {
            MainComponent: SwitchDamageCustomer
        }
    case DIALOG.NAME.ASSIGN_PREFERRED_BRANCH:
        return {
            MainComponent: AssignPreferredBranch
        }
    case DIALOG.NAME.BRANCH_MANAGEMENT_REMOVE_BRANCH:
        return {
            MainComponent: RemoveBranch
        }
    case DIALOG.NAME.BRANCH_MANAGEMENT_REMOVE_GROUP:
        return {
            MainComponent: RemoveGroup
        }
    case DIALOG.NAME.BRANCH_MANAGEMENT_ADD_USERS_TO_GROUP:
        return{
            MainComponent: EditUsersInGroup
        }
    case DIALOG.NAME.BRANCH_MANAGEMENT_REMOVE_USER_FROM_GROUP:
        return{
            MainComponent: RemoveUserFromGroup
        }
    case DIALOG.NAME.REOPEN_TASK:
        return{
            MainComponent: ReopenTask
        }
    case DIALOG.NAME.EDIT_USER_GROUPS_FROM_LIST:
        return{
            MainComponent: EditUserGroups
        }
    case DIALOG.NAME.MO_TASKS_DELETE: 
        return{
            MainComponent: MoTasksDelete
        }
    case DIALOG.NAME.EXPORT_TO_LAWFIRM_CONFIRMATION: 
        return{
            MainComponent: ExportToLawFirmConfirmation
        }
    case DIALOG.NAME.USER_NOTIFICATION_SETTINGS:
        return {
            MainComponent: NotificationSettings
        }
    case DIALOG.NAME.CREATE_VEHICLE_AND_KEEPER_QR_CODE:
    case DIALOG.NAME.CREATE_VEHICLE_AND_KEEPER_QR_CODE_FROM_DAMAGE_MANAGER:
        return {
            MainComponent: CreateVehicleAndKeeperQRCode
        }
    case DIALOG.NAME.CREATE_SMART_CONNECT_EMAIL:
        return {
            MainComponent: CreateSmartConnectEmail
        }
    case DIALOG.NAME.CREATE_COST_COVERAGE:
        return{
            MainComponent: CreateCostCoverage
        }
    case DIALOG.NAME.CREATE_BRANCH_QR_CODE:
        return {
            MainComponent: CreateBranchQRCode
        }
    case DIALOG.NAME.DELETE_ACCOUNTLOGO:
        return {
            MainComponent: DeleteAccountlogo
        }
    case DIALOG.NAME.CREATE_POWER_OF_ATTORNEY:
        return {
            MainComponent: CreatePowerOfAttorney
        }
    case DIALOG.NAME.TASK_ASSIGN_USER:
        return {
            MainComponent: TaskAssignUser
        }
    case DIALOG.NAME.ADD_COMPREHENSIVE_DAMAGE_TYPE:
        return {
            MainComponent: AddComprehensiveDamageType
        }
    case DIALOG.NAME.EDIT_COMPREHENSIVE_DAMAGE_TYPE:
        return {
            MainComponent: EditComprehensiveDamageType
        }
    case DIALOG.NAME.DELETE_COMPREHENSIVE_DAMAGE_TYPE:
        return {
            MainComponent: DeleteComprehensiveDamageType
        }
    default: return {
        AdditionalHeader: DefaultHeader,
        MainComponent: DefaultDialogContent
    }
        
    }
}