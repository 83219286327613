import React, {useEffect} from 'react'
import { GlobalStyle } from 'application/style'
import * as Fragments from './fragments'
import {WaitingScreen} from 'application/components/building_blocks/waiting_screen'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { exists } from 'application/common/data_helpers'
import { PagePreloader } from 'application/components/building_blocks/page_preloader'
import { useTranslation } from 'react-i18next'

const LayoutBaseSharedResourceComponent = ({ 
    waitingScreen,
    onSaveSharedResourceData,
    onLoadSharedResourceData,
    children,
    match,
    dialogsOpen,
    sharedResourceType,
    sharedResource,
    dataInitiallyLoaded = true
}) => {
    const {t} = useTranslation()
    const sharedResourceId = match.params.sharedResourceId

    useEffect(()=>{
        onSaveSharedResourceData(sharedResourceId, sharedResourceType)  
    },[onSaveSharedResourceData, sharedResourceId, sharedResourceType])

    useEffect(()=>{
        if(exists(sharedResourceType)){
            onLoadSharedResourceData()
        } 
    },[onLoadSharedResourceData, sharedResourceType])

    if(!dataInitiallyLoaded && !sharedResource.dataLoaded){
        return <React.Fragment>
            <GlobalStyle />
            <PagePreloader text={t('Loading')} />
        </React.Fragment>
    } 

    return <React.Fragment>
        <GlobalStyle />
        <Fragments.Wrapper 
            blurred={
                waitingScreen.active 
                && !dialogsOpen 
                && !waitingScreen.inDialog
            }
        >
            {children}
        </Fragments.Wrapper>
        <WaitingScreen />
    </React.Fragment>
}

export const LayoutBaseSharedResource = withRouter(LayoutBaseSharedResourceComponent)