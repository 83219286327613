import { FilterFragment } from 'application/components/building_blocks/filters/_common'
import { TextInputPureComponent } from 'application/components/controls/form/text_input'
import { Label } from 'application/components/fragments/typography'
import { setQuery, useQuery } from 'application/components/hooks/use_query'
import * as filters from 'application/filters'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'



export const DamageLicensePlateSearchFilter = ({
    routeParameters,
    filterDefinition = filters.damages.vehicleLicensePlate,
    onChange=()=>{},
    disabled = false
}) => {
    const location = useLocation()
    const navigate = useNavigate()
    const {t} = useTranslation()
    const query = useQuery()

    const { FILTERS, OFFSET, LIMIT} = routeParameters
    const { LICENSE_PLATE } = FILTERS

    const defaultLicensePlate = query.getStringParam(LICENSE_PLATE, filterDefinition.defaultValue)

    const handleSearch = (licencePlate) => {
        setQuery(
            navigate,
            location, 
            location.pathname,
            [
                {
                    key: filterDefinition.filterKey,
                    value: licencePlate
                }
            ],
            true,
            [
                OFFSET,
                LIMIT
            ]
        )
        onChange(licencePlate)
    }

    return <FilterFragment.FilterRow>
        <FilterFragment.FilterLabel className='filterLabel'>
            <Label cssMargin='0 16px 0 0'>
                {t('Search by license plate')}
            </Label>
        </FilterFragment.FilterLabel>
        <FilterFragment.FilterAction
            maxWidth='266px'
        >
            <TextInputPureComponent 
                onKeyDownEnter={e => {
                    handleSearch(e.target.value)
                }}
                defaultValue={defaultLicensePlate}
                disabled={disabled}
            />
        </FilterFragment.FilterAction>
    </FilterFragment.FilterRow>
}