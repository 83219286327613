import React from 'react'
import { store } from 'application/redux/create_store'
import { Provider } from 'react-redux'
import { OidcProvider } from 'redux-oidc'
import { defaultUserManager } from 'application/common/user_manager'
import { BrowserRouter } from 'react-router-dom'
import {RoutesComponent} from 'application/components/pages/_routes'
import { ThemeProvider } from 'styled-components'
import { theme } from 'application/style/theme'
import 'application/translation'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import 'material-icons-font/material-icons-font.css'
import 'application/style/fonts.css'
import { Toaster } from 'application/components/building_blocks/toaster'
import { ErrorDisplay } from 'application/components/building_blocks/error_display'


export const App = () => {
    return (
        <Provider store={store}>
            <OidcProvider store={store} userManager={defaultUserManager}>
                <ThemeProvider theme={theme}>
                    <BrowserRouter>
                        <RoutesComponent />
                    </BrowserRouter>
                    <ErrorDisplay />
                    <Toaster />        
                </ThemeProvider>
            </OidcProvider>
        </Provider>
    )
}