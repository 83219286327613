import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { Footer } from 'application/components/dialogs/_common/footer'
import { FlexBox } from 'application/components/fragments/flex_box'
import { INFOBOX, ROUTE_PARAMETERS } from 'application/constants'
import { InfoBox } from 'application/components/controls/info_box'
import { FontIcon } from '@ec/ui-controls-react'
import { PushBottom16, Spacer } from 'application/components/pages/_common'
import { Paragraph } from 'application/components/fragments/typography'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { DamagesTableCondensed } from 'application/components/tables/refactored/damages/table_damages_condensed'
import { isNull } from 'application/common/data_helpers'
import { DamageLicensePlateSearchFilter } from 'application/components/building_blocks/filters/damage_licenceplate_search_filter'


const MoveMessage = (
    { 
        abort, 
        dialog, 
        onMoveMessage,
        onSearchDamages
    }
) => {
    const { t } = useTranslation()

    const {
        damagesList, 
        subject,
        messageId,
        damageId
    } = dialog.payload
    const successMsg = t('Message') + ': "' + subject + '" ' + t('has been successfully moved')

    const [selectedDamage, setSelectedDamage] = useState(null)
    const hasNoSelectedDamage = isNull(selectedDamage)


    const handleSubmit = () => {       

        const metaData = {
            damageId,
            messageId, 
            successMsg,
            destinationDamageId: selectedDamage.id
        }

        onMoveMessage(
            dialog.name, 
            null,
            metaData
        )
    }

    const handleLoadDamages = useCallback((
        offset,
        limit,
        orderBy,
        direction,
        filterQuery
    ) => {
        onSearchDamages(
            dialog.name,
            offset,
            limit,
            orderBy,
            direction,
            filterQuery
        )
    }, [dialog.name, onSearchDamages])

    return <React.Fragment>
        <DialogMainContent>
            <FlexBox 
                cssRules='min-height: 100px;'
                justifyContent='center'
                alignItems='center'
                flexDirection='column'
                overflow='auto'
            >
                <Paragraph>
                    {t('Please select the destination damage case for this message')}:
                </Paragraph>
                <Paragraph>
                    <strong>{t('Subject')}: "{subject}"</strong>
                </Paragraph>
                <PushBottom16/>
                <InfoBox 
                    type={INFOBOX.TYPES.STANDARD} 
                    direction='row' 
                    cssMargin='0' 
                >
                    <FontIcon icon='info_outline' />
                    <Spacer basis='10px' />
                    <span>
                        {t('Attachments of the message will also be moved to the selected damage case.')}
                    </span>
                    <Spacer />
                </InfoBox>
                <PushBottom16/>
                <FlexBox>
                    <DamageLicensePlateSearchFilter
                        routeParameters={ROUTE_PARAMETERS.DAMAGES_CONDENSED}
                    />
                </FlexBox>
                <PushBottom16/>

                <DamagesTableCondensed
                    damagesListLoading = {damagesList?.loading || false}
                    damagesListLoaded = {damagesList?.loaded || false}
                    damagesListTotal = {damagesList?.total || 0}
                    damagesList = {damagesList?.damages || []}
                    onLoadDamagesList = {handleLoadDamages}
                    selectedElement = {selectedDamage}
                    setSelectedElement = {setSelectedDamage}
                />
            </FlexBox>
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={handleSubmit}
            submitText='Move message'
            disabled={hasNoSelectedDamage}
        />
    </React.Fragment>

}

export const MoveMessageComponent = withDialogDataLoader(MoveMessage)