
import React from 'react'
import PropTypes from 'prop-types'
import { ActivityDetail, ActivityDetailWrapper, BranchDetail } from 'application/components/controls/activity_details/common'


export const MessageMovedDetails = (
    {
        activity
    }
) => {

    return <ActivityDetailWrapper>
        <BranchDetail 
            activity={activity} 
        />
        <ActivityDetail 
            label='Licenseplate' 
            value={activity.data.damage.licensePlate} 
        />
        <ActivityDetail 
            label='Subject' 
            value={activity.data.subject} 
        />
    </ActivityDetailWrapper>
}

MessageMovedDetails.propTypes = {
    activity: PropTypes.object.isRequired
}