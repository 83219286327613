import React from 'react'
import { FlexBox } from 'application/components/fragments/flex_box'
import { DescriptionField } from '../../common/setting_description'
import { useTranslation } from 'react-i18next'
import { PushBottom16 } from 'application/components/pages/_common'
import { PureSelect } from 'application/components/controls/form'
import { CONTRACTOR_TYPE } from 'application/constants/contractors'
import { useKickstartDefaultLawfirms } from 'application/components/pages/internal/settings/ui_settings/setting_editor_components/edit_kickstart_default_lawfirms/use_kickstart_default_lawfirms'
import { useModules } from 'application/components/hooks/use_modules'
import { MODULE_NAME } from 'application/constants'

export const EditKickstartDefaultLawfirmsComponent = ({
    definition,
    updateSettings,
    getSetting,
    branches,
    branchContractorsList
}) => {
    const {t} = useTranslation()


    const {
        mappedBranches,
        onUpdateBranchLawfirm
    } = useKickstartDefaultLawfirms(definition, getSetting, branches, updateSettings)

    const handleChange = (val, branch) => {
        onUpdateBranchLawfirm(branch.id, val.lawfirm)
    }

    const {
        isBranchModuleApplied
    } = useModules()

    return <FlexBox flexDirection='column'>

        <DescriptionField
            description={definition.description}
        />

        <PushBottom16 />

        <FlexBox cssRules='gap: 16px;' justifyContent='flex-end' flexDirection='column'>
            {
                mappedBranches.map((mappedBranch, i) => {
                    const availableContractors = branchContractorsList?.find(branch => branch.id === mappedBranch.id)?.allowedContractors?.filter(contractor => contractor.type === CONTRACTOR_TYPE.LAWYER) ?? []

                    if(availableContractors.length === 0 && !isBranchModuleApplied(MODULE_NAME.VOIGT_MODULE, mappedBranch.id)) {
                        return <React.Fragment key={i} />
                    }

                    const options = availableContractors.map(lawfirm => ({
                        value: lawfirm.externalId,
                        label: lawfirm.name,
                        lawfirm: {
                            ...lawfirm,
                            id: lawfirm.externalId
                        }
                    }))

                    const mappedOptions = isBranchModuleApplied(MODULE_NAME.VOIGT_MODULE, mappedBranch.id) 
                        ? [
                            {
                                value: MODULE_NAME.VOIGT_MODULE,
                                label: 'Kanzlei Voigt',
                                lawfirm: {
                                    id: MODULE_NAME.VOIGT_MODULE,
                                    name: 'Kanzlei Voigt',
                                    type: ''
                                }
                            },
                            ...options
                        ]
                        : options

                    return <React.Fragment key={i}>
                        <label>{mappedBranch.name}</label>
                        <PureSelect
                            defaultValue={mappedBranch.lawfirm ? {
                                label: mappedBranch.lawfirm.name,
                                value: mappedBranch.lawfirm.id,
                                lawfirm: mappedBranch.lawfirm
                            }: null}
                            options={mappedOptions}
                            onChange={(val) => {
                                handleChange(val, mappedBranch)
                            }}
                            noOptionsMessage={() => t('Start typing to search')}
                            placeholder={t('Search lawfirm')}
                            isClearable={false}
                        />
                    </React.Fragment>
                })
            }
          
        </FlexBox>

    </FlexBox>
}