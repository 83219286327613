import React from 'react'
import { ROUTE_PARAMETERS } from 'application/constants'
import * as filters from 'application/filters'
import { ListFilter } from 'application/filters/filter_class'
import { STORAGE } from 'application/constants'
import * as storage from 'application/storage'
import { DateComponent } from '@ec/ui-controls-react'
import { nameHelpers } from 'application/common'


const tableName = STORAGE.TABLE_NAME.DAMAGES.CONDENSED

export const configuration = {
    name: tableName,
    filters: {
        match: [],
        query: [
            new ListFilter(filters.damages.vehicleLicensePlate)
        ],
        property: [
            // new ListFilter(filters.users.getUsersForSpecificBranch)
        ],
        static: []
    },
    sorting:  {
        sortParamKey: ROUTE_PARAMETERS.DAMAGES_CONDENSED.ORDER_BY.KEY,
        directionParamKey: ROUTE_PARAMETERS.SORT_DIRECTIONS.ASCENDING,
        defaultSortParamValue: ROUTE_PARAMETERS.DAMAGES_CONDENSED.ORDER_BY.VALUES.CREATED_AT,
        defaultDirectionParamValue: ROUTE_PARAMETERS.SORT_DIRECTIONS.ASCENDING
    },
    pagination: {
        offset: ROUTE_PARAMETERS.DAMAGES_CONDENSED.OFFSET,
        limit: ROUTE_PARAMETERS.DAMAGES_CONDENSED.LIMIT,
        getItemsPerPage: () => {
            return storage.tableConfig.getItemsPerPage(tableName, 25) 
        }
    },
    columns: [
        {
            width: 25,
            label: 'License plate',
            hasSorting: true,
            sortParamValue: ROUTE_PARAMETERS.DAMAGES_CONDENSED.ORDER_BY.VALUES.LICENSE_PLATE,
            CellContent: ({element}) => <React.Fragment>{element.vehicle.licensePlate}</React.Fragment>
        },
        {
            width: 15,
            label: 'Creation date',
            hasSorting: true,
            sortParamValue: ROUTE_PARAMETERS.DAMAGES_CONDENSED.ORDER_BY.VALUES.CREATED_AT,
            CellContent: ({element}) => <DateComponent 
                date={element.createdAt} 
            />
        },
        {
            width: 15,
            label: 'Damage date',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <DateComponent 
                date={element.incidentDate} 
            />
        },
        {
            width: 25,
            label: 'Assignee',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <React.Fragment>{nameHelpers.getFullName(element.assignee)}</React.Fragment>
        },
       
    ],
    rows: {
        selectable: {
            Component: null,
            additionalProps: null
        },
        linked: null,
        details: null
    },  
    massOperations: {
        available: false
    }
}
