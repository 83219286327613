import React from 'react'
import { Table } from 'application/components/tables/refactored/_common'
import { configuration } from './configuration'
import {  useTableLoader } from 'application/components/hooks'
import { withRouter } from 'application/components/higher_order_components'
import { isNull } from 'application/common/data_helpers'






const DamagesTableCondensedComponent = (
    {
        damagesListLoading,
        damagesListLoaded,
        damagesListTotal,
        damagesList,
        onLoadDamagesList,
        match,
        selectedElement,
        setSelectedElement
    }
) => {

    useTableLoader(
        onLoadDamagesList, 
        match, 
        configuration,
        null
    )

    return <Table 
        configuration={configuration}
        elementsLoading={damagesListLoading}
        elementsLoaded={damagesListLoaded}
        elementsTotal={damagesListTotal}
        elements={damagesList}
        emptyMessage='No damages found'
        selectItem = {
            (element) => setSelectedElement(element)
        }
        deselectItem = {
            () => setSelectedElement(null)
        }
        selectedItemsFromParent = {
            isNull(selectedElement) 
                ? [] 
                : [selectedElement]
        }
        cssOverflow='auto'
    />
}

export const DamagesTableCondensed = withRouter(DamagesTableCondensedComponent)