import React from 'react'
import {DamageManagerContainer} from '../container'
import { SubPageLayout } from 'application/components/pages/_common'
import {DamageManagerInboxTabbarButtons} from './tab_bar_buttons'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'
import { MESSAGE_ORIGIN, ROUTE_PARAMETERS, ROUTES } from 'application/constants'
import { Navigate, useLocation } from 'react-router-dom'
import { useQuery } from 'application/components/hooks'
import { Inbox } from 'application/components/building_blocks/inbox_refactored'
import { MessageDetails } from 'application/components/building_blocks/inbox_refactored/details/container'
import { exists } from 'application/common/data_helpers'

export const DamageManagerInboxComponent = ({
    inbox, 
    match, 
    downloadAttachment, 
    openMessageRelatedDialog,
    damage,
    onUnreadMessageFilterChange
}) => {
    const { messageId, damageId } = match.params
    const query = useQuery()
    const offset = query.getParsedParam(ROUTE_PARAMETERS.DAMAGE_MANAGER.INBOX.OFFSET, 0)

    const location = useLocation()
    const {
        damageManagerLink
    } = useApplicationLinks()

    const getMessageLink = (message) => {
        return damageManagerLink(
            ROUTES.DAMAGE_MANAGER_INBOX + '/' + message.id,
            damageId
        ) + location.search
    }

    const getPaginationLinkBase = () => {
        return damageManagerLink(
            ROUTES.DAMAGE_MANAGER_INBOX, 
            damageId
        )
    }

    if(inbox.messagesLoaded){ //only redirect if messages are loaded to prevent infinite loop / race condition
        if(inbox.messages.length > 0) {
            if (inbox.deletedMessages.includes(messageId)) { 
                const redirectUrl = damageManagerLink(ROUTES.DAMAGE_MANAGER_INBOX, damageId) + location.search
                return <Navigate 
                    to={redirectUrl} 
                    replace={true}
                />
            } 
        }else{
            if(offset > 0){ //redirect to first page if there are no messages on current page
                const redirectUrl = damageManagerLink(ROUTES.DAMAGE_MANAGER_INBOX, damageId)
                return <Navigate 
                    to={redirectUrl} 
                    replace={true}
                />
            }
        }
    }

    const checkIfOutgoing = (message) => {
        return message.origin === MESSAGE_ORIGIN.ESY360
    }

    return <DamageManagerContainer
        titleSegments={['Messages']}
        headline='Messages'
        tabBarButtons={ <DamageManagerInboxTabbarButtons /> }
        mainPaddingLeft='16px'
    >
        <SubPageLayout>
            <Inbox
                baseUrlParams={ROUTE_PARAMETERS.DAMAGE_MANAGER.INBOX}
                messages={inbox.messages}
                messagesLoaded={inbox.messagesLoaded}
                messagesTotal={inbox.messagesTotal}
                getMessageLink={getMessageLink}
                selectedMessageId={messageId}
                onUnreadMessageFilterChange={onUnreadMessageFilterChange}
                getLinkBase={getPaginationLinkBase}
                showDetails={messageId === inbox.details?.id}
                showNoMessageInfo={!exists(messageId)}
                checkIfOutgoing={checkIfOutgoing}
            >
                <MessageDetails
                    openMessageRelatedDialog={openMessageRelatedDialog}
                    downloadAttachment={downloadAttachment}
                    messageDetails={inbox.details}
                    messageDetailsLoaded={inbox.detailsLoaded}
                    messageDetailsLoading={inbox.detailsLoading}
                    checkIfOutgoing={checkIfOutgoing}
                    damage={damage}
                />
            </Inbox>
        </SubPageLayout>
    </DamageManagerContainer>
}
