import React from 'react'
// import * as Fragments from './fragments'
import { ROUTES } from 'application/constants'
import { useParams } from 'react-router-dom'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'
import { MessageDetails } from 'application/components/building_blocks/inbox_refactored/details/container'
import { DamageDisplay } from 'application/components/building_blocks/damage_display'

export const MessageDetailsWithDamageManagerLink = (
    { 
        messageDetails, 
        openMessageRelatedDialog, 
        downloadAttachment,
        messageDetailsLoaded,
        messageDetailsLoading,
        hideMarkAsUnreadButton,
        checkIfOutgoing,
        damage
    }
) => {
    const params = useParams()
    const {damageId } = params

    const {
        internalAccountLink
    } = useApplicationLinks()

    return <MessageDetails
        messageDetails={messageDetails}
        openMessageRelatedDialog={openMessageRelatedDialog}
        downloadAttachment={downloadAttachment}
        messageDetailsLoaded={messageDetailsLoaded}
        messageDetailsLoading={messageDetailsLoading}
        hideMarkAsUnreadButton={hideMarkAsUnreadButton}
        checkIfOutgoing={checkIfOutgoing}
        damage={damage}
    >
        <DamageDisplay
            damage={damage} 
            to={
                internalAccountLink( ROUTES.DAMAGE_MANAGER_BASE + damageId) 
            }
        />
    </MessageDetails>
}
