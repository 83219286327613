import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, deleteQuery } from 'application/components/hooks/use_query'
import { PushBottom16, Spacer } from 'application/components/pages/_common'
import { InfoBox } from 'application/components/controls/info_box'
import { IconButton } from 'application/components/controls/icon_button'
import { SearchComboBoxFilterName } from 'application/components/controls/search_combo_box_filter_name'
import { useLocation, useNavigate } from 'react-router-dom'

export const DamageSearchFilterDisplay = (
    { 
        filterKey
    }
) => {
    const query = useQuery()
    const location = useLocation()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const filterName = query.getStringParam(filterKey)
    const searchTerm = query.getStringParam(filterName)
    const hasSearch = searchTerm !== ''

    const handleResetDamageSearch = () => {
        const paramKeys = [
            filterKey, 
            filterName
        ]
        deleteQuery(
            navigate, 
            location, 
            location.pathname, 
            paramKeys, 
            true
        )
    }

    if (!hasSearch) {
        return null
    }

    return <React.Fragment>
        <InfoBox direction='row' cssMargin='0'>
            <span>{t('Active search')}:</span>
            <strong>"{searchTerm}"</strong>
            <span>{t('within criteria')}</span>
            <span>
                "<SearchComboBoxFilterName filterName={filterName} />"
            </span>
            <Spacer />
            <IconButton 
                iconKey='close' 
                width='12px' 
                height='16px' 
                onButtonClick={handleResetDamageSearch} 
            />
        </InfoBox>
        <PushBottom16/>
    </React.Fragment>
}