import { fileUploadRequestBuilder, fileUploadRequestBuilderPin } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'
import { exists } from 'application/common/data_helpers'

export const postAttachment = (params) => (
    {
        authenticated: (payload) => {
            const fileParams = exists(params.categoryId) 
                ? [{
                    key: 'Category.Id',
                    value: params.categoryId
                }] : 
                []

            const request = fileUploadRequestBuilder(`damages/${params.damageId}/documents`, payload.file, fileParams)
            return apiCall(request, [], [422 /* Unprocessable Entity / is thrown when a virus is detected -> don't retry */])
        },
        qr: (payload) => {
            const fileParams = [{
                key: 'Category.Id',
                value: !exists(params.categoryId) ? '0' : params.categoryId
            }] 
            const request = fileUploadRequestBuilderPin(`external/${params.sharedResourceType}/${params.sharedResourceId}/damages/${params.damageId}/documents`, params.pin, payload.file, fileParams)
            return apiCall(request, [], [422 /* Unprocessable Entity / is thrown when a virus is detected -> don't retry */])
        },
        kickstart: (payload) => {
            const fileParams = []
            const request = fileUploadRequestBuilderPin(`external/qr/${params.sharedResourceId}/damages/${params.damageId}/documents`, params.pin, payload.file, fileParams)
            return apiCall(request, [], [422 /* Unprocessable Entity / is thrown when a virus is detected -> don't retry */])
        },
        sharedResource: (payload) => {
            const fileParams = []
            const request = fileUploadRequestBuilderPin(
                `external/${params.sharedResourceType}/${params.sharedResourceId}/damage/documents`, 
                params.pin, 
                payload.file, 
                fileParams
            )
            return apiCall(request, [], [422 /* Unprocessable Entity / is thrown when a virus is detected -> don't retry */])
        }
    }
)