import { put, takeLeading } from 'redux-saga/effects'
import { api } from 'application/api'
import { updateDialogDataByName } from 'application/redux/actions/dialogs'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { ACTIONS } from 'application/constants'




export function* searchDamagesForDialogSaga(action) {

    const { 
        // branchId,
        offset = 0, 
        limit = 25,
        orderBy = 'createdAt',
        direction,
        filterQuery,
        dialogName
    } = action


    const emptyDamagesList = {
        damages: [],
        total: 0,
    }

    yield put(
        updateDialogDataByName(
            dialogName, 
            { 
                damagesList: {
                    ...emptyDamagesList,
                    loaded: false,
                    loading: true
                }
            }
        )
    )

    try {
        const damagesList = yield resourceCall(
            api.gateway.damages.getDamages, 
            { 
                offset,
                limit,
                filterQuery,
                orderBy,
                direction
            }
        )

        yield put(
            updateDialogDataByName(
                dialogName, 
                { 
                    damagesList: {
                        ...damagesList,
                        loaded: true,
                        loading: false
                    }
                }
            )
        )
    } catch (e) {
        yield put(
            updateDialogDataByName(
                dialogName, 
                { 
                    damagesList: {
                        ...emptyDamagesList,
                        loaded: true,
                        loading: false
                    }
                }
            )
        ) 
    }
}


export function* searchDamagesForDialogWatcher() {
    yield takeLeading(
        ACTIONS.DAMAGES_SEARCH_DAMAGE_FOR_DIALOG, 
        searchDamagesForDialogSaga
    )
}