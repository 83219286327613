import { ACTIONS } from 'application/constants'

export const getBranchMessagesListRequest = (branchId, offset, limit, onlyUnreadMessages) => {
    return {
        type: ACTIONS.INBOX_BRANCH_GET_MESSAGES_REQUEST,
        branchId, offset, limit, onlyUnreadMessages
    }
}

export const populateBranchMessagesList = (messages, messagesTotal) => {
    return {
        type: ACTIONS.INBOX_BRANCH_POPULATE_MESSAGES,
        messages, messagesTotal
    }
}

export const updateBranchDeletedMessages = (messageId) => {
    return {
        type: ACTIONS.INBOX_BRANCH_UPDATE_DELETED_MESSAGES,
        messageId
    }
}

export const resetBranchDeletedMessages = () => {
    return {
        type: ACTIONS.INBOX_BRANCH_RESET_DELETED_MESSAGES
    }
}

export const resetBranchMessageDetails = () => {
    return {
        type: ACTIONS.INBOX_BRANCH_RESET_MESSAGE_DETAILS
    }
}

export const getBranchMessageByIdRequest = (damageId, messageId) => {
    return {
        type: ACTIONS.INBOX_BRANCH_GET_MESSAGE_BY_ID,
        damageId, messageId
    }
}


export const populateBranchMessageDetails = (messageDetails) => ({
    type: ACTIONS.INBOX_BRANCH_POPULATE_MESSAGE_DETAILS,
    messageDetails,
})
