import React from 'react'
import { TextInputWrapper } from '../_common'


export const TextInputPureComponent = React.forwardRef(({
    autoFocus = false,
    htmlId = '',
    name = '',
    defaultValue = '',
    value = '',
    inputType = 'text',
    onChange = () => {},
    disabled = false,
    readOnly = false,
    placeholder = '',
    controlled = false,
    onKeyDown = () => {},
    onKeyDownEnter = () => {},
    required = false,
    maxLength
}, ref) => {

    const handleKeyDown = (event) => {
        onKeyDown(event)
        if (event.nativeEvent.which === 13 || event.nativeEvent.keyCode === 13) {
            onKeyDownEnter(event)
        }
    }

    return <TextInputWrapper disabled={disabled}>
        {
            controlled 
                ? <input 
                    autoFocus={autoFocus} 
                    id={htmlId} 
                    name={name} 
                    ref={ref} 
                    type={inputType} 
                    onChange={onChange} 
                    disabled={disabled} 
                    readOnly={readOnly}
                    placeholder={placeholder}
                    value={value}
                    onKeyDown={handleKeyDown}
                    required={required}
                    autoComplete="on"
                    maxLength={maxLength}
                />
                : <input 
                    autoFocus={autoFocus} 
                    id={htmlId} 
                    name={name} 
                    defaultValue={defaultValue} 
                    ref={ref} 
                    type={inputType} 
                    onChange={onChange} 
                    disabled={disabled} 
                    readOnly={readOnly}
                    placeholder={placeholder}
                    onKeyDown={handleKeyDown}
                    required={required}
                    autoComplete="on"
                    maxLength={maxLength}
                />
        }
    </TextInputWrapper>
})