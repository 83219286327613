import { FontIcon } from '@ec/ui-controls-react'
import { exists } from 'application/common/data_helpers'
import { getFullName } from 'application/common/name_helpers'
import { ForSpecificBranchPermissions } from 'application/components/context/permissions'
import { DateComponent } from 'application/components/controls/date'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label/component'
import { LocalTime } from 'application/components/controls/local_time'
import { MessageSubject } from 'application/components/controls/message_subject'
import { ThemedPreloader } from 'application/components/controls/themed_preloader'
import { PushBottom24, PushBottom48 } from 'application/components/pages/_common'
import { DIALOG, USER_PERMISSONS } from 'application/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { useTheme } from 'styled-components'
import * as Fragments from './fragments'

export const MessageDetailsComponent = (
    { 
        messageDetails = null, 
        openMessageRelatedDialog,
        downloadAttachment, 
        messageDetailsLoaded,
        messageDetailsLoading,
        onSetMessageUnread,
        hideMarkAsUnreadButton = false,
        currentUser,
        checkIfOutgoing,
        damage,
        children
    }
) => {
    const theme = useTheme()
    const {t} = useTranslation()
    const params = useParams()
    const path = useLocation().pathname
    const {damageId, sharedResourceId } = params
    const documents = messageDetails?.documents?.documents || []

    if (messageDetailsLoading){
        return <ThemedPreloader />
    }

    if (messageDetails === null || !messageDetailsLoaded){
        return null
    } 

    const isOutgoing = checkIfOutgoing(messageDetails)

    const handleDeleteMessage = () => {

        const payload =                     { 
            messageId: messageDetails.id, 
            ...messageDetails, 
            damageId: params.damageId,
            path: path
        }

        openMessageRelatedDialog(
            DIALOG.NAME.DELETE_MESSAGE, 
            true, 
            payload,
            'Delete message'
        )
    }

    const handleMoveMessage = () => {

        const payload =                     { 
            messageId: messageDetails.id, 
            ...messageDetails, 
            damageId: params.damageId,
            path: path
        }

        openMessageRelatedDialog(
            DIALOG.NAME.MOVE_MESSAGE, 
            true, 
            payload,
            'Move message'
        )
    }

    
    return <Fragments.MessageDetailsRowWrapper className='messageDetailsRowWrapper'>
        <Fragments.MessageDetailsWrapper className='messageDetailsWrapper'>
            <Fragments.HeaderWrapper>
                <Fragments.HeaderLeft>
                    <Fragments.MessageSender>
                        {t('from')}{': '}
                        { messageDetails.sender.name }
                        { messageDetails.sender.email !== undefined && <React.Fragment>
                            &lt;{messageDetails.sender.email}&gt;
                        </React.Fragment>}
                    </Fragments.MessageSender>
                    <Fragments.MessageTime>
                        <DateComponent date={messageDetails.createdAt} />, 
                        <LocalTime date={messageDetails.createdAt} />
                    </Fragments.MessageTime>
                </Fragments.HeaderLeft>
            </Fragments.HeaderWrapper>
            <Fragments.ButtonWrapper>
                {
                    !messageDetails.markedAsUnread && !hideMarkAsUnreadButton && !isOutgoing && <IconButtonWithLabel
                        label={t('Mark as unread')}
                        iconKey='grade'
                        onButtonClick={() => onSetMessageUnread(damageId, messageDetails.id)}
                        iconSize={theme.fontSize.medium} 
                        fontSize={theme.fontSize.medium}
                    />
                }

                <ForSpecificBranchPermissions
                    permissions={[USER_PERMISSONS.MESSAGE.WRITE]}
                    branchId={damage?.branchId ?? null}
                    ignoreCondition={exists(sharedResourceId)}
                >
                    <IconButtonWithLabel
                        label={t('Move message')}
                        iconKey='drive_file_move'
                        onButtonClick={handleMoveMessage}
                        iconSize={theme.fontSize.medium} 
                        fontSize={theme.fontSize.medium} 
                    />
                        
                </ForSpecificBranchPermissions>

                <ForSpecificBranchPermissions
                    permissions={[USER_PERMISSONS.MESSAGE.DELETE]}
                    branchId={damage?.branchId ?? null}
                    ignoreCondition={exists(sharedResourceId)}
                >
                    <IconButtonWithLabel
                        label={t('Delete message')}
                        iconKey='delete'
                        onButtonClick={handleDeleteMessage}
                        iconSize={theme.fontSize.medium} 
                        fontSize={theme.fontSize.medium} 
                    />
                        
                </ForSpecificBranchPermissions>

                
            </Fragments.ButtonWrapper>
            <Fragments.SubHeaderWrapper>
                {
                    exists(messageDetails.readStatus) && <React.Fragment>
                        {t('read by')}:&nbsp;&nbsp;<strong>{messageDetails.readStatus.name}</strong>&nbsp;&nbsp;<DateComponent date={messageDetails.readStatus.readAt} />, <LocalTime date={messageDetails.readStatus.readAt} />
                    </React.Fragment>
                }
                {
                    messageDetails.markedAsUnread && <React.Fragment>
                        <FontIcon icon='grade' fontSize={theme.fontSize.medium} color={theme.color.success}/> {t('This message will be treated as unread until it is opened again.')}
                    </React.Fragment>
                }
                {
                    !exists(messageDetails.readStatus) && messageDetails.markedAsRead && !messageDetails.markedAsUnread && !isOutgoing && <React.Fragment>
                        {t('read by')}:&nbsp;&nbsp;<strong>{getFullName(currentUser)}</strong>&nbsp;&nbsp;<DateComponent />, <LocalTime />
                    </React.Fragment>
                }
            </Fragments.SubHeaderWrapper>
            {
                documents.length > 0 && (
                    <Fragments.MessageAttachmentWrapper>
                        {
                            documents.map(
                                (document, i) => (
                                    <Fragments.MessageAttachment key={i}>
                                        <IconButtonWithLabel
                                            iconKey="attach_file"
                                            label={document.name}
                                            fontSize="15px"
                                            iconSize="15px"
                                            onButtonClick={
                                                () => downloadAttachment(
                                                    document.id, 
                                                    document.name, 
                                                    damageId
                                                )
                                            }
                                        />
                                    </Fragments.MessageAttachment>
                                )
                            )
                        }
                    </Fragments.MessageAttachmentWrapper>
                )
            }
            <PushBottom24 />
            <Fragments.MessageBodyWrapper>
                <MessageSubject subject={messageDetails.subject} />
                <PushBottom48 />
                <Fragments.MessageBody>
                    { messageDetails.body }
                </Fragments.MessageBody>
            </Fragments.MessageBodyWrapper>
        </Fragments.MessageDetailsWrapper>
        {children}
    </Fragments.MessageDetailsRowWrapper>
}
