
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as Fragments from '../fragments'
import { ShortenedString } from 'application/components/controls/shortened_string'


export const MessageMovedActivity = (
    {
        activity
    }
) => {
    const {t} = useTranslation()

    return <React.Fragment>
        <Fragments.ActivityTitle>
            {t('Message moved')}
        </Fragments.ActivityTitle>
        <Fragments.ActivityParagraph>
            {t('Subject')}: <ShortenedString text={activity.data.subject} shortenAt={35}/> 
        </Fragments.ActivityParagraph>
    </React.Fragment>
}

MessageMovedActivity.propTypes = {
    activity: PropTypes.object.isRequired
}