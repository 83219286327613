import { ACTIONS } from 'application/constants'
import { defaultStore } from 'application/redux/reducers/default_store'

export const inboxBranchReducer =  (state = defaultStore.pages.inboxBranch, action) => {
    switch (action.type) {

    case ACTIONS.INBOX_BRANCH_GET_MESSAGES_REQUEST: return {
        ...state,
        messagesLoading: true,
        messagesLoaded: false
    }
    case ACTIONS.INBOX_BRANCH_POPULATE_MESSAGES: return {
        ...state,
        messagesLoading: false,
        messagesLoaded: true,
        messages: action.messages,
        messagesTotal: action.messagesTotal
    }
    case ACTIONS.INBOX_BRANCH_UPDATE_DELETED_MESSAGES: return {
        ...state,
        deletedMessages: [...state.deletedMessages, action.messageId]
    }
    case ACTIONS.INBOX_BRANCH_RESET_DELETED_MESSAGES: return {
        ...state,
        deletedMessages: []
    }
    case ACTIONS.INBOX_BRANCH_GET_MESSAGE_BY_ID: return {
        ...state,
        details: null,
        detailsLoaded: false,
        detailsLoading: true
    }
    case ACTIONS.INBOX_BRANCH_RESET_MESSAGE_DETAILS: return {
        ...state,
        details: null,
        detailsLoaded: false,
        detailsLoading: false
    }
    case ACTIONS.INBOX_BRANCH_POPULATE_MESSAGE_DETAILS: return {
        ...state,
        details: {
            ...action.messageDetails,
            markedAsRead: true
        },
        detailsLoaded: true,
        detailsLoading: false,
        messages: state.messages.map(message => {
            if (message.id === action.messageDetails.id) {
                return {
                    ...message,
                    markedAsRead: true,
                    markedAsUnread: false
                }
            }
            return message
        })
    }
    case ACTIONS.COMMON_MARK_MESSAGE_UNREAD: return {
        ...state,
        details: {
            ...state.details,
            markedAsUnread: true,
            readStatus: null
        },
        messages: state.messages.map(message => {
            if (message.id === action.messageId) {
                return {
                    ...message,
                    readStatus: null,
                    markedAsUnread: true
                }
            }
            return message
        })
    }
    case ACTIONS.RESET_ACCOUNT_RELATED_DATA: {
        return defaultStore.pages.inboxBranch
    }
    default:
        return state
    }
}
