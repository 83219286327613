import { api } from 'application/api'
import { showToast } from 'application/redux/actions/notifications'
import { put } from 'redux-saga/effects'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { updateDeletedMessages } from 'application/redux/actions/pages/inbox'
import { updateBranchDeletedMessages } from 'application/redux/actions/pages/inbox/branch_inbox'
import { updateDamageManagerDeletedMessages } from 'application/redux/actions/pages/damage_manager/inbox'
import { updateWfpDeletedMessages } from 'application/redux/actions/pages/workflow_panel/inbox'

export function* dialogMoveMessageSaga(action) {
    const {
        damageId, 
        messageId,
        destinationDamageId,
        successMsg
    } = action.metaData
    
    yield put(
        showWaitingScreen('Moving message')
    )

    yield resourceCall(
        api.gateway.messages.moveMessage, 
        {
            damageId, 
            messageId,
            destinationDamageId
        }
    )

    yield put(
        showToast(successMsg)
    )

    //reuse the delete message pattern to force the ui to update the message list
    yield put(updateDeletedMessages(messageId))
    yield put(updateBranchDeletedMessages(messageId))
    yield put(updateDamageManagerDeletedMessages(messageId))
    yield put(updateWfpDeletedMessages(messageId))
    

    yield put(
        hideWaitingScreen()
    )
}