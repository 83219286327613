export const en = {
    translation: {
        'login': 'Login',
        'username': 'Username',
        'password': 'Password',
        'legal notice': 'Legal Notice',
        'privacy': 'Privacy Policy',
        'conditions': 'Term & Conditions',
        'aktualisiert': 'updated',
        'erstellt': 'created',
        'Nachricht': 'Message',
        'Aufgabe': 'Task',
        'Dokument': 'Document',
        'Schaden': 'Damage',
        'Polizei': 'Police',
        'Zeuge': 'Witness',
        'GeschaedigterHalter': 'Vehicle keeper',
        'Bearbeiter': 'Assignee',
        'Anwalt': 'Lawyer',
        'UnfallgegnerHalter': 'Accident opponent',
        'Unfallhergang': 'Accident description',
        'Fahrzeug': 'Vehicle',
        'CaseFileInfoUpdatedNotificationTitle': 'Case file information updated',
        'ContactInfoUpdatedNotificationTitle': 'Lawfirm contact updated',
        'DamageAssigneeChangedNotificationTitle': 'Damage was assigned',
        'DamageCreatedNotificationTitle': 'Damage was created',
        'DamageDeletedNotificationTitle': 'Damage was deleted',
        'DamageDescriptionChangedNotificationTitle': 'Damage report changed',
        'DamageIncidentDateChangedNotificationTitle': 'Damage incident date changed',
        'DamageJobNumberChangedNotificationTitle': 'Damage job number changed',
        'DamageNoteChangedNotificationTitle': 'Case note changed',
        'DamageStateChangedNotificationTitle': 'Damage state changed',
        'DamageTypeChangedNotificationTitle': 'Damage type changed',
        'DocumentCreatedNotificationTitle': 'New document was created',
        'DocumentDeletedNotificationTitle': 'Document was deleted',
        'ExpertCommunicationDekraEstablishedNotificationTitle': 'Expert communication with DEKRA established',
        'ExpertCommunicationDekraFailedNotificationTitle': 'Expert communication with DEKRA failed',
        'ExpertCommunicationDekraInitiatedNotificationTitle': 'Expert communication with DEKRA initiated',
        'ExpertCommunicationOtherInitiatedNotificationTitle': 'Other Expert communication initiated',
        'InvoiceCreatedNotificationTitle': 'New invoice was created',
        'InvoiceDeletedNotificationTitle': 'Invoice was deleted',
        'LiabilityInquiryUpdatedNotificationTitle': 'Liability inquiry updated',
        'MessageCreatedNotificationTitle': 'Message was created',
        'MessageDeletedNotificationTitle': 'Message was deleted',
        'TaskCommentCreatedNotificationTitle': 'New comment',
        'TaskCreatedNotificationTitle': 'Task was created',
        'TaskDeletedNotificationTitle': 'Task was deleted',
        'TaskStateChangedNotificationTitle': 'Task state changed',
        'VehicleKeeperChangedNotificationTitle': 'Vehicle keeper changed',
        'VehicleUpdatedNotificationTitle': 'Vehicle data changed',
        'groupAccount AdminsDescription': 'Users in this groups have administrative permissions in ALL branches.',
        'groupBenutzerDescription': 'Users in this groups have regular permissions in ALL branches.',
        'EmailSettingTitle': 'Email',
        'RealtimeNotificationSettingTitle': 'Real time',
        'firstRegisteredAt': 'First registered',
        'RepairAuthorizationChangedNotificationTitle': 'Repairauthorization status changed',
        'SmsSettingTitle': 'SMS',
        'contractorHeadlineExpert': 'Experts',
        'contractorHeadlineLawfirmWebakte': 'Lawfirms',
        'contractorHeadlineDekra': 'DEKRA',
        'licensePlate': 'License plate',
        'vehicleState': 'Vehicle state',
        'ReadyToDriveSafeForTraffic': 'Ready to drive, safe for traffic',
        'ReadyToDriveNotSafeForTraffic': 'Ready to drive, not safe for traffic',
        'NotReadyToDrive': 'Not ready to drive',
        'state': 'State',
        'AcknowledgementUpdatedNotificationTitle': 'Acknowledgement from lawfirm',
        'TaskAssigneeChangedNotificationTitle': 'Task assignee has changed',
        'Only Updates of the last X days will be visible.': 'Only Updates of the last <strong><1>{{changeLogTimeRange}}</1> days</strong> will be visible.',
        'contractorHeadlineModuleContractor': 'Contractors',
        'location': 'Location',
        'line1': 'Street',
        'locality': 'City',
        'postalCode': 'Postal code',
        'NotSet': 'Not set',
    }
}