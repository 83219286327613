export const DIALOG = {
    SIZE: {
        SMALL: 'small',
        MEDIUM: 'medium',
        MEDIUM50: 'medium50',
        LARGE: 'large',
    },
    NAME: {
        CREATE_DAMAGE: 'DIALOG_CREATE_DAMAGE',
        CREATE_DAMAGE_AND_CLOSE_DIALOG: 'CREATE_DAMAGE_AND_CLOSE_DIALOG',
        CREATE_DAMAGE_KICKSTART: 'DIALOG_CREATE_DAMAGE_KICKSTART',
        CREATE_DAMAGE_KICKSTART_AND_COMMISSION_DEKRA: 'CREATE_DAMAGE_KICKSTART_AND_COMMISSION_DEKRA',
        CREATE_DAMAGE_KICKSTART_AND_CLOSE_DIALOG: 'DIALOG_CREATE_DAMAGE_KICKSTART_AND_CLOSE_DIALOG',
        CREATE_TASK_FROM_TASKBOARD: 'CREATE_TASK_FROM_TASKBOARD',
        CREATE_TASK_FROM_DAMAGE_MANAGER: 'CREATE_TASK_FROM_DAMAGE_MANAGER',
        DELETE_MESSAGE: 'DIALOG_DELETE_MESSAGE',
        MOVE_MESSAGE: 'DIALOG_MOVE_MESSAGE',
        DELETE_DAMAGE: 'DIALOG_DELETE_DAMAGE',
        DELETE_ATTACHMENT: 'DIALOG_DELETE_ATTACHMENT',
        DELETE_ATTACHMENT_WORKFLOW_PANEL: 'DELETE_ATTACHMENT_WORKFLOW_PANEL',
        DELETE_TASK: 'DIALOG_DELETE_TASK',
        DELETE_TASK_FROM_DAMAGE_MANAGER: 'DELETE_TASK_FROM_DAMAGE_MANAGER',
        EDIT_CURRENT_USER: 'EDIT_CURRENT_USER',
        EDIT_USER_GROUPS_FROM_LIST: 'EDIT_USER_GROUPS_FROM_LIST',
        EDIT_USER_FROM_LIST: 'EDIT_USER_FROM_LIST',
        EDIT_CUSTOMER: 'EDIT_CUSTOMER',
        EDIT_CUSTOMER_FROM_WIDGET: 'EDIT_CUSTOMER_FROM_WIDGET',
        EDIT_CUSTOMER_FROM_DIALOG: 'EDIT_CUSTOMER_FROM_DIALOG',
        DELETE_USER: 'DELETE_USER',
        DELETE_CUSTOMER: 'DELETE_CUSTOMER',
        CREATE_CUSTOMER: 'CREATE_CUSTOMER',
        CREATE_USER: 'CREATE_USER',
        CATEGORY_UPLOAD: 'CATEGORY_UPLOAD',
        CATEGORY_UPLOAD_FROM_DOCUMENT_CENTER: 'CATEGORY_UPLOAD_FROM_DOCUMENT_CENTER',
        CATEGORY_SELECT_FROM_TASK_WIDGET: 'CATEGORY_SELECT_FROM_TASK_WIDGET',
        CATEGORY_UPLOAD_WORKFLOW_PANEL: 'CATEGORY_UPLOAD_WORKFLOW_PANEL',
        COMMISSION_DEKRA_EXPERT: 'COMMISSION_DEKRA_EXPERT',
        COMMISSION_DEKRA_EXPERT_FROM_DAMAGE_MANAGER: 'COMMISSION_DEKRA_EXPERT_FROM_DAMAGE_MANAGER',
        COMMISSION_OTHER_EXPERT: 'COMMISSION_OTHER_EXPERT',
        COMISSION_LAWFIRM: 'COMISSION_LAWFIRM',
        DELETE_LAWFIRM: 'DELETE_LAWFIRM',
        CREATE_CASE_NOTES: 'CREATE_CASE_NOTES',
        EDIT_CASE_NOTES: 'EDIT_CASE_NOTES',
        CREATE_TASK_FROM_WORKFLOW_PANEL: 'CREATE_TASK_FROM_WORKFLOW_PANEL',
        CANCEL_PAYMENT_WORKFLOW_PANEL: 'CANCEL_PAYMENT_WORKFLOW_PANEL',
        CREATE_PAYMENT_WORKFLOW_PANEL: 'CREATE_PAYMENT_WORKFLOW_PANEL',
        CREATE_INVOICE_WORKFLOW_PANEL: 'CREATE_INVOICE_WORKFLOW_PANEL',
        DELETE_INVOICE_WORKFLOW_PANEL: 'DELETE_INVOICE_WORKFLOW_PANEL',
        CREATE_INVOICE_LAWYER_COMMUNICATION: 'CREATE_INVOICE_LAWYER_COMMUNICATION',
        CANCEL_PAYMENT_LAWYER_COMMUNICATION: 'CANCEL_PAYMENT_LAWYER_COMMUNICATION',
        CREATE_PAYMENT_LAWYER_COMMUNICATION: 'CREATE_PAYMENT_LAWYER_COMMUNICATION',
        DELETE_INVOICE_LAWYER_COMMUNICATION: 'DELETE_INVOICE_LAWYER_COMMUNICATION',
        ADD_DOCUMENTS_TO_INVOICE: 'ADD_DOCUMENTS_TO_INVOICE',
        EDIT_TASK: 'DIALOG_EDIT_TASK',
        DELETE_TASK_WORKFLOW_PANEL: 'DELETE_TASK_WORKFLOW_PANEL',
        EDIT_TASK_WORKFLOW_PANEL: 'EDIT_TASK_WORKFLOW_PANEL',
        CREATE_MESSAGE_WORKFLOW_PANEL: 'CREATE_MESSAGE_WORKFLOW_PANEL',
        DAMAGE_INVOICES: 'DAMAGE_INVOICES',
        BRANCH_MANAGEMENT_ADD_ROLE: 'BRANCH_MANAGEMENT_ADD_ROLE',
        BRANCH_MANAGEMENT_ADD_GROUP: 'BRANCH_MANAGEMENT_ADD_GROUP',
        BRANCH_MANAGEMENT_REMOVE_GROUP: 'BRANCH_MANAGEMENT_REMOVE_GROUP',
        BRANCH_MANAGEMENT_ADD_BRANCH: 'BRANCH_MANAGEMENT_ADD_BRANCH',
        BRANCH_MANAGEMENT_CHANGE_BRANCH: 'BRANCH_MANAGEMENT_CHANGE_BRANCH',
        BRANCH_MANAGEMENT_REMOVE_BRANCH: 'BRANCH_MANAGEMENT_REMOVE_BRANCH',
        ADD_DOCUMENT_CATEGORY: 'ADD_DOCUMENT_CATEGORY',
        DELETE_DOCUMENT_CATEGORY: 'DELETE_DOCUMENT_CATEGORY',
        EDIT_DOCUMENT_CATEGORY: 'EDIT_DOCUMENT_CATEGORY',
        ADD_CUSTOM_STATE: 'ADD_CUSTOM_STATE',
        DELETE_CUSTOM_STATE: 'DELETE_CUSTOM_STATE',
        EDIT_CUSTOM_STATE: 'EDIT_CUSTOM_STATE',
        EDIT_BRANCH: 'EDIT_BRANCH',
        SWITCH_DAMAGE_CUSTOMER: 'SWITCH_DAMAGE_CUSTOMER',
        ASSIGN_PREFERRED_BRANCH: 'ASSIGN_PREFERRED_BRANCH',
        BRANCH_MANAGEMENT_ADD_USERS_TO_GROUP: 'BRANCH_MANAGEMENT_ADD_USERS_TO_GROUP',
        BRANCH_MANAGEMENT_REMOVE_USER_FROM_GROUP: 'BRANCH_MANAGEMENT_REMOVE_USER_FROM_GROUP',
        BRANCH_MANAGEMENT_EDIT_GROUP: 'BRANCH_MANAGEMENT_EDIT_GROUP',
        REOPEN_TASK: 'REOPEN_TASK',
        CREATE_DAMAGE_UPDATE_INSURANCE_DATA: 'CREATE_DAMAGE_UPDATE_INSURANCE_DATA',

        MO_DOCUMENTS_DELETE: 'MO_DOCUMENTS_DELETE',
        MO_DOCUMENTS_CHANGE_CATEGORY: 'MO_DOCUMENTS_CHANGE_CATEGORY',
        MO_DOCUMENTS_IS_EXPORTED_TO_LAWFIRM: 'MO_DOCUMENTS_IS_EXPORTED_TO_LAWFIRM',
        MO_DAMAGES_DELETE: 'MO_DAMAGES_DELETE',
        MO_DAMAGES_CHANGE_STATE: 'MO_DAMAGES_CHANGE_STATE',
        MO_TASKS_DELETE: 'MO_TASKS_DELETE',
        EXPORT_TO_LAWFIRM_CONFIRMATION: 'EXPORT_TO_LAWFIRM_CONFIRMATION',
        EXPORT_TO_LAWFIRM_CONFIRMATION_FROM_DOCUMENT_SIDEBAR: 'EXPORT_TO_LAWFIRM_CONFIRMATION_FROM_DOCUMENT_SIDEBAR',
        RE_EXPORT_TO_LAWFIRM_CONFIRMATION_FROM_DOCUMENT_SIDEBAR: 'RE_EXPORT_TO_LAWFIRM_CONFIRMATION_FROM_DOCUMENT_SIDEBAR',
        WRITE_BACK_TO_LAST_DIALOG: 'WRITE_BACK_TO_LAST_DIALOG',
        USER_NOTIFICATION_SETTINGS: 'USER_NOTIFICATION_SETTINGS',
        CREATE_MESSAGE_FOR_LAWFIRM: 'CREATE_MESSAGE_FOR_LAWFIRM',
        CREATE_VEHICLE_AND_KEEPER_QR_CODE: 'CREATE_VEHICLE_AND_KEEPER_QR_CODE',
        CREATE_VEHICLE_AND_KEEPER_QR_CODE_FROM_DAMAGE_MANAGER: 'CREATE_VEHICLE_AND_KEEPER_QR_CODE_FROM_DAMAGE_MANAGER',
        CREATE_SMART_CONNECT_EMAIL: 'CREATE_SMART_CONNECT_EMAIL',
        CREATE_COST_COVERAGE: 'CREATE_COST_COVERAGE',
        CREATE_BRANCH_QR_CODE: 'CREATE_BRANCH_QR_CODE',
        CREATE_POWER_OF_ATTORNEY: 'CREATE_POWER_OF_ATTORNEY',
        DELETE_ACCOUNTLOGO: 'DELETE_ACCOUNTLOGO',
        TASK_ASSIGN_USER: 'TASK_ASSIGN_USER',
        ADD_COMPREHENSIVE_DAMAGE_TYPE: 'ADD_COMPREHENSIVE_DAMAGE_TYPE',
        EDIT_COMPREHENSIVE_DAMAGE_TYPE: 'EDIT_COMPREHENSIVE_DAMAGE_TYPE',
        DELETE_COMPREHENSIVE_DAMAGE_TYPE: 'DELETE_COMPREHENSIVE_DAMAGE_TYPE',
    }
}

export const DIALOG_AUTO_LOADER_PARAMS = {
    KEY: 'dialog',
    USER_NOTIFICATION_SETTINGS: 'userNotificationSettings'
}