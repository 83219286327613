import React, {useEffect} from 'react'

import { GlobalStyle } from 'application/style'
import * as Fragments from './fragments'
import {WaitingScreen} from 'application/components/building_blocks/waiting_screen'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { exists } from 'application/common/data_helpers'

const LayoutBaseSharedResource = ({ 
    sharedResource,  
    waitingScreen,
    onValidatePin,
    children,
    match,
    dialogsOpen,
    storePin
}) => {
    const hasPin = exists(sharedResource.pin)
    const pin = sharedResource.pin || ''
    const sharedResourceId = match.params.sharedResourceId
    const shouldValidatePin = !sharedResource.pinValidationPending 

    useEffect(() => {
        hasPin && shouldValidatePin && onValidatePin(pin, sharedResourceId, storePin)
    }, [hasPin, pin, onValidatePin, sharedResourceId, storePin, shouldValidatePin])

    return <React.Fragment>
        <GlobalStyle />
        <Fragments.Wrapper blurred={waitingScreen.active && !dialogsOpen && !waitingScreen.inDialog}>
            {children}
        </Fragments.Wrapper>
        <WaitingScreen />
    </React.Fragment>
}

export const LayoutBaseSharedResourceComponent =  withRouter(LayoutBaseSharedResource)