import { fetchRequestBuilder, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'

export const moveMessage = (params) => (
    {
        authenticated: (payload) => {
            const request = fetchRequestBuilder(
                `damages/${params.damageId}/messages/${params.messageId}/move/${params.destinationDamageId}`,
                HTTP_METHOD.POST, 
                payload
            )
            return apiCall(request)
        },
        sharedResource: () => null
    }
)