import { ACTIONS } from 'application/constants'

export const getMessagesListRequest = (offset, limit, onlyUnreadMessages) => {
    return {
        type: ACTIONS.INBOX_GET_MESSAGES_REQUEST,
        offset, limit, onlyUnreadMessages
    }
}

export const populateMessagesList = (messages, messagesTotal) => {
    return {
        type: ACTIONS.INBOX_POPULATE_MESSAGES,
        messages, messagesTotal
    }
}

export const updateDeletedMessages = (messageId) => {
    return {
        type: ACTIONS.INBOX_UPDATE_DELETED_MESSAGES,
        messageId
    }
}

export const resetDeletedMessages = () => {
    return {
        type: ACTIONS.INBOX_RESET_DELETED_MESSAGES
    }
}

export const resetMessageDetails = () => {
    return {
        type: ACTIONS.INBOX_RESET_MESSAGE_DETAILS
    }
}

export const getMessageByIdRequest = (damageId, messageId) => {
    return {
        type: ACTIONS.INBOX_GET_MESSAGE_BY_ID,
        damageId, messageId
    }
}


export const populateMessageDetails = (messageDetails) => ({
    type: ACTIONS.INBOX_POPULATE_MESSAGE_DETAILS,
    messageDetails,
})
