import React from 'react'
import { LayoutAllBranches } from 'application/components/pages/_layout'
import { useTranslation } from 'react-i18next'
import { Inbox } from 'application/components/building_blocks/inbox_refactored'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'
import { MESSAGE_ORIGIN, ROUTE_PARAMETERS, ROUTES } from 'application/constants'
import { Navigate, useLocation } from 'react-router-dom'
import { MessageDetailsWithDamageManagerLink } from 'application/components/building_blocks/inbox_refactored/details'
import { useQuery } from 'application/components/hooks'
import { exists } from 'application/common/data_helpers'

export const InboxComponent = ({
    inbox, 
    match, 
    downloadAttachment, 
    openMessageRelatedDialog,
    onlyUnreadMessages,
    setOnlyUnreadMessages,
    onUnreadMessageFilterChange
}) => {
    const { messageId } = match.params
    const {t} = useTranslation()
    const query = useQuery()
    const offset = query.getParsedParam(ROUTE_PARAMETERS.INBOX.OFFSET, 0)

    const location = useLocation()
    const {
        internalAccountLink
    } = useApplicationLinks()

    const getMessageLink = (message) => {
        return internalAccountLink(
            ROUTES.ALL_BRANCHES_INBOX + '/' + message.damage.id + '/' + message.id
        ) + location.search
    }

    const getPaginationLinkBase = () => {
        return internalAccountLink(ROUTES.ALL_BRANCHES_INBOX)
    }

    if(inbox.messagesLoaded){ //only redirect if messages are loaded to prevent infinite loop / race condition
        if(inbox.messages.length > 0) {
            if (inbox.deletedMessages.includes(messageId)) { 
                const redirectUrl = internalAccountLink(ROUTES.ALL_BRANCHES_INBOX) + location.search
                return <Navigate 
                    to={redirectUrl} 
                    replace={true}
                />
            } 
        }else{
            if(offset > 0){ //redirect to first page if there are no messages on current page
                const redirectUrl = internalAccountLink(ROUTES.ALL_BRANCHES_INBOX)
                return <Navigate 
                    to={redirectUrl} 
                    replace={true}
                />
            }
        }
    }

    const checkIfOutgoing = (message) => {
        return message.origin === MESSAGE_ORIGIN.ESY360
    }

    return <LayoutAllBranches
        titleSegments={['Messages', 'All branches']}
        headlineSpecific={t('Messages')}
    >
        <Inbox 
            baseUrlParams={ROUTE_PARAMETERS.INBOX}
            messages={inbox.messages}
            messagesLoaded={inbox.messagesLoaded}
            messagesTotal={inbox.messagesTotal}
            getMessageLink={getMessageLink}
            selectedMessageId={messageId}
            onlyUnreadMessages={onlyUnreadMessages} 
            setOnlyUnreadMessages={setOnlyUnreadMessages}
            getLinkBase={getPaginationLinkBase}
            showDetails={messageId === inbox.details?.id}
            showNoMessageInfo={!exists(messageId)}
            onUnreadMessageFilterChange={onUnreadMessageFilterChange}
            checkIfOutgoing={checkIfOutgoing}
        >
            <MessageDetailsWithDamageManagerLink 
                openMessageRelatedDialog={openMessageRelatedDialog}
                downloadAttachment={downloadAttachment}
                messageDetails={inbox.details}
                messageDetailsLoaded={inbox.detailsLoaded}
                messageDetailsLoading={inbox.detailsLoading}
                damage={inbox?.details?.damage}
                checkIfOutgoing={checkIfOutgoing}
            />
        </Inbox>
    </LayoutAllBranches>
}