import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'application/components/higher_order_components/with_router'
import {InboxComponent} from './component'
import { ROUTE_PARAMETERS, ROUTES } from 'application/constants'
import { useQuery } from 'application/components/hooks/use_query'
import { useNavigate } from 'react-router-dom'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'
import { buildQueryString } from 'application/common/route_helpers'

const InboxEffectsComponent = (props) => {
    const { getMessages, getMessageDetails, match, inbox, resetMessages } = props
    const { damageId, messageId } = match.params
    const navigate = useNavigate()
    const query = useQuery()
    const offset = query.getParsedParam(ROUTE_PARAMETERS.INBOX.OFFSET, 0)
    const limit = query.getParsedParam(ROUTE_PARAMETERS.INBOX.LIMIT, 10)
    const onlyUnreadMessages = query.getParsedParam(ROUTE_PARAMETERS.INBOX.ONLY_UNREAD_MESSAGES, 0) === 1

    useEffect(() => {
        getMessages(offset, limit, onlyUnreadMessages)
    }, [getMessages, inbox.deletedMessages, limit, offset, onlyUnreadMessages])

    useEffect(() => {
        if(messageId && damageId){
            getMessageDetails(damageId, messageId)
        }else{
            resetMessages() //reset the message details if no message is selected & reset deleted messages to be able to navigate back to the message
        }
    }, [damageId, getMessageDetails, messageId, resetMessages])

    //handle the unread message filter change
    const {
        internalAccountLink
    } = useApplicationLinks()

    const handleUnreadMessageFilterChange = (onlyUnread) => {
        const queryString = buildQueryString(onlyUnread 
            ? [{
                key: ROUTE_PARAMETERS.INBOX.ONLY_UNREAD_MESSAGES,
                value: 1
            }] : [])

        const newUrl = internalAccountLink(ROUTES.ALL_BRANCHES_INBOX) + queryString
        navigate(newUrl)
    }

    return <InboxComponent 
        {...props} 
        onUnreadMessageFilterChange={handleUnreadMessageFilterChange}
    />
}

InboxEffectsComponent.propTypes = {
    getMessages: PropTypes.func.isRequired,
}

export const InboxEffects = withRouter(InboxEffectsComponent)