import { connect } from 'react-redux'
import { DamageManagerInboxEffects } from './effects'
import { withLoggedInUser } from 'application/components/context/logged_in'
import { downloadAttachment } from 'application/redux/actions/attachments'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { getDamageManagerMessageByIdRequest, getDamageManagerMessagesListRequest, resetDamageManagerDeletedMessages, resetDamageManagerMessageDetails } from 'application/redux/actions/pages/damage_manager/inbox'

const mapStateToProps = state => ({
    inbox: state.pages.damageManager.inbox,
    damage: state.pages.damageManager.shared.damage
})

const mapDispatchToProps = dispatch => {
    return {
        getMessages: (damageId, offset, limit, onlyUnreadMessages) => {
            dispatch(getDamageManagerMessagesListRequest(damageId, offset, limit, onlyUnreadMessages))
        },
        getMessageDetails: (damageId, messageId) => {
            dispatch(getDamageManagerMessageByIdRequest(damageId, messageId))
        },
        downloadAttachment: (
            attId, 
            attName, 
            damageId
        ) => {
            dispatch(
                downloadAttachment(
                    attId, 
                    attName, 
                    damageId
                )
            )
        },
        openMessageRelatedDialog: (
            name,
            initiallyLoaded, 
            payload, 
            title
        ) => {
            dispatch(
                openDialogByName(
                    name,
                    initiallyLoaded, 
                    payload, 
                    title
                )
            )
        },
        resetDamageManagerMessages: () => {
            dispatch(resetDamageManagerDeletedMessages())
            dispatch(resetDamageManagerMessageDetails())
        }
    }
}

export const DamageManagerInbox = connect(
    mapStateToProps,
    mapDispatchToProps
)(withLoggedInUser(DamageManagerInboxEffects))