import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WidgetSubHeadline, WidgetErrorMessage, BulletList, BulletListItem, BulletListLabel } from 'application/components/widgets/_common'
import { germanDateIfDateString } from 'application/common/date_time_helpers'


const VehicleDataList = ({payload}) => {
    const {t} = useTranslation()
    const bulletKeys = Object.keys(payload).filter( 
        key => key !== 'licensePlate'
    ).filter( 
        key => key !== 'notNullMarker'
    )

    return <BulletList>
        {
            bulletKeys.map(
                (key, i) => {
                    // if the value is an object, we need to recursively render it
                    if(typeof payload[key] === 'object'){
                        return <BulletListItem key={i}>
                            <BulletListLabel cssMargingBottom='4px' cssWidth='76px'>{t(key)}:</BulletListLabel><VehicleDataList keys={Object.keys(payload[key])} payload={payload[key]} />
                        </BulletListItem>
                    }

                    const value = germanDateIfDateString(payload[key])
                    return <BulletListItem key={i}>
                        <BulletListLabel cssWidth='76px'>{t(key)}:</BulletListLabel>{t(value)}
                    </BulletListItem>
                }
            )
        }
    </BulletList>
}
 
const DamageVehicle = ({payload, loadingFailed}) => {
    const {t} = useTranslation()

    if(loadingFailed){
        return <WidgetErrorMessage>{t('vehicle could not be loaded')}</WidgetErrorMessage>
    }
    
    return <React.Fragment>
        <WidgetSubHeadline>{payload.licensePlate}</WidgetSubHeadline>
        <BulletList>
            <VehicleDataList
                payload={payload}
            />
        </BulletList>
    </React.Fragment>
}

DamageVehicle.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

export { DamageVehicle }