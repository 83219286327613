import React from 'react'
import {withRouter} from 'application/components/higher_order_components/with_router'
import * as Common from 'application/components/pages/_common'
import { BreakLine32 } from 'application/components/pages/external/welcome/fragments'
import { Inbox } from 'application/components/building_blocks/inbox_refactored'
import { MessageDetails } from 'application/components/building_blocks/inbox_refactored/details/container'
import { MESSAGE_ORIGIN, ROUTE_PARAMETERS, ROUTES } from 'application/constants'
import { useQuery } from 'application/components/hooks'
import { Navigate, useLocation } from 'react-router-dom'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'
import { exists } from 'application/common/data_helpers'


const WorkflowPanelInbox = ({
    inbox, 
    match, 
    downloadAttachment, 
    openMessageRelatedDialog,
    onUnreadMessageFilterChange
}) => {
    const { messageId } = match.params
    const query = useQuery()
    const offset = query.getParsedParam(ROUTE_PARAMETERS.WORKFLOW_PANEL.INBOX.OFFSET, 0)

    const location = useLocation()
    const {
        internalWorkflowPanelLink
    } = useApplicationLinks()

    const getMessageLink = (message) => {
        return internalWorkflowPanelLink(
            ROUTES.EXT_WORKFLOW_PANEL_MESSAGE + '/' + message.id
        ) + location.search
    }

    const getPaginationLinkBase = () => {
        return internalWorkflowPanelLink(
            ROUTES.EXT_WORKFLOW_PANEL_MESSAGE
        )
    }

    if(inbox.messagesLoaded){ //only redirect if messages are loaded to prevent infinite loop / race condition
        if(inbox.messages.length > 0) {
            if (inbox.deletedMessages.includes(messageId)) { 
                const redirectUrl = internalWorkflowPanelLink(ROUTES.EXT_WORKFLOW_PANEL_MESSAGE) + location.search
                return <Navigate 
                    to={redirectUrl} 
                    replace={true}
                />
            } 
        }else{
            if(offset > 0){ //redirect to first page if there are no messages on current page
                const redirectUrl = internalWorkflowPanelLink(ROUTES.EXT_WORKFLOW_PANEL_MESSAGE)
                return <Navigate 
                    to={redirectUrl} 
                    replace={true}
                />
            }
        }
    }

    const checkIfOutgoing = (message) => {
        return message.origin === MESSAGE_ORIGIN.LAWFIRM
    }

    return <React.Fragment>
        <BreakLine32 />
        <Common.VerticalContainer>
            <Inbox
                baseUrlParams={ROUTE_PARAMETERS.WORKFLOW_PANEL.INBOX}
                messages={inbox.messages}
                messagesLoaded={inbox.messagesLoaded}
                messagesTotal={inbox.messagesTotal}
                getMessageLink={getMessageLink}
                selectedMessageId={messageId}
                onUnreadMessageFilterChange={onUnreadMessageFilterChange}
                getLinkBase={getPaginationLinkBase}
                showDetails={messageId === inbox.details?.id}
                showNoMessageInfo={!exists(messageId)}
                checkIfOutgoing={checkIfOutgoing}
            >
                <MessageDetails
                    openMessageRelatedDialog={openMessageRelatedDialog}
                    downloadAttachment={downloadAttachment}
                    messageDetails={inbox.details}
                    messageDetailsLoaded={inbox.detailsLoaded}
                    messageDetailsLoading={inbox.detailsLoading}
                    hideMarkAsUnreadButton={true}
                    checkIfOutgoing={checkIfOutgoing}
                />
            </Inbox>
        </Common.VerticalContainer>
    </React.Fragment>
}

export const WorkflowPanelInboxComponent = withRouter(WorkflowPanelInbox)